<template>
  <div style="height: 100%">
 <div class="checklist-edit">
   <div class="step-box">
     <head-layout :show-icon="false" head-title="处理进度" ></head-layout>
     <el-steps direction="vertical" :active="2" style="margin-top: 30px;align-items: center;height: 50%;">
       <el-step title="上报"></el-step>
       <el-step title="评估"></el-step>
       <el-step title="整改"></el-step>
       <el-step title="验收"></el-step>
     </el-steps>
   </div>
  <div class="form-box">
    <div class="">
      <head-layout :head-title="headTitle" :head-btn-options="btnOptions" @head-cancel="headCancel"></head-layout>
      <div class="formContentBox">
        <div class="formMain">
          <div style="padding: 0 12px">
            <el-form ref="dataForm" :model="dataForm" label-width="150px" disabled>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="事项编号" prop="cvCode">
                    <el-input v-model="dataForm.cvCode" disabled placeholder="请输入事项编号"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="地点" prop="prjName">
                    <el-input  v-model="dataForm.location"
                               placeholder="请填写地点"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="报告人" prop="reportUserName">
                    <el-input v-model="dataForm.reportUserName" placeholder="请填写报告人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报告部门" prop="reportDeptName">
                    <el-input v-model="dataForm.reportDeptName" placeholder="请填写报告部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="报告时间" prop="reportDate">
                    <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm.reportDate" placeholder="请选择报告时间"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="描述" prop="cvDesc">
                    <el-input type="textarea" :autosize="{ minRows: 2}" show-word-limit v-model="dataForm.cvDesc"
                              placeholder="请填写描述"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="事项来源" prop="sourceType">
                    <avue-select :props="{
              label: 'dictValue',
              value: 'dictKey',
            }" v-model="dataForm.sourceType" placeholder="请选择事项来源" type="tree"
                                 :dic="HD_SOURCE_TYPE"></avue-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="现场" prop="prjAs">
                    <div class="content">
                      <div v-for="(item, index) in treeData" :key="item.id" class="img-content" @click="getNodeClick(item)">
                        <img :src="item.thumbnailLink" alt=""/>
                      </div>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div v-if="hide != 'hide'" class="">
      <head-layout head-title="事项评估" :head-btn-options="headBtnOptions"  @head-saveBack="headSave(true)" @head-save="headSave(false)"></head-layout>
      <div class="formContentBox">
        <div class="formMain">
          <div style="padding: 0 12px">
            <el-form ref="dataForm1" :model="dataForm1" label-width="150px" :disabled="formType == 'view'" :rules="formType == 'view' ? {} :rules">
              <el-row>
                <el-col :span="8">
                  <el-form-item label="事项核实" prop="passFlag">
                    <el-radio v-model="dataForm1.passFlag" label="y">核实通过</el-radio>
                    <el-radio v-model="dataForm1.passFlag" label="n">核实不通过</el-radio>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                  <el-form-item label="责任人" prop="respUserName">
                    <el-input v-model="dataForm1.respUserName" @focus="handleUserShow('respUserName')" placeholder="请选择责任人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                  <el-form-item label="责任部门" prop="respDeptName">
                    <el-input v-model="dataForm1.respDeptName" @focus="handleDeptShow('respDeptName')"  placeholder="请选择责任部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                  <el-form-item label="提交整改计划" prop="rectifyPlanFlag">
                    <el-checkbox false-label="n" true-label="y" v-model="dataForm1.rectifyPlanFlag"></el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                  <el-form-item label="整改人" prop="rectifyUserName">
                    <el-input v-model="dataForm1.rectifyUserName" @focus="handleUserShow('rectifyUserName')"  placeholder="请选择整改人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                  <el-form-item label="整改部门" prop="rectifyDeptName">
                    <el-input v-model="dataForm1.rectifyDeptName"  @focus="handleDeptShow('rectifyDeptName')" placeholder="请选择整改部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm1.passFlag == 'n' || dataForm1.passFlag == 'y'">
                  <el-form-item label="评估时间" prop="assessmentTime">
                    <el-date-picker :picker-options="pickerOptionsStart"  type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm1.assessmentTime"  placeholder="请选择评估时间"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                  <el-form-item label="验收人" prop="acceptUserName">
                    <el-input v-model="dataForm1.acceptUserName" @focus="handleUserShow('acceptUserName')"  placeholder="请选择验收人"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                  <el-form-item label="验收部门" prop="acceptDeptName">
                    <el-input v-model="dataForm1.acceptDeptName"  @focus="handleDeptShow('acceptDeptName')" placeholder="请选择验收部门"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8" v-if="dataForm1.passFlag == 'y'">
                  <el-form-item label="整改期限" prop="rectifyLimitDate">
                    <el-date-picker type="datetime" :picker-options="pickerOptionsStart"  value-format="yyyy-MM-dd HH:mm:ss" v-model="dataForm1.rectifyLimitDate"  placeholder="请选择整改期限"></el-date-picker>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24" v-if="dataForm1.passFlag == 'y'" key="rectifyRequest">
                  <el-form-item label="整改要求" prop="rectifyRequest" >
                    <el-input type="textarea"  maxlength="500" :autosize="{ minRows: 2}" show-word-limit v-model="dataForm1.rectifyRequest" placeholder="请填写整改要求" ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24" v-if="dataForm1.passFlag == 'n'" key="remark">
                  <el-form-item label="备注" prop="remark" >
                    <el-input type="textarea"  :autosize="{ minRows: 2}" show-word-limit v-model="dataForm1.remark" placeholder="请填写备注" ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
 </div>
    <el-dialog title="部门选择" :visible.sync="respDeptNameShow" width="80%">
      <DeptDialog ref="DeptDialog" @select-data="respDeptNameFun"></DeptDialog>
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="respUserShow" width="80%" top="8vh">
      <UserDetpDialog ref="UserDetpDialog" @select-data="respUserFun"></UserDetpDialog>
    </el-dialog>
    <el-dialog
      v-dialog-drag
      title="附件预览"
      :modal="true"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :visible.sync="showFileDialog"
      width="70%"
    >
      <iframe
        ref="fileIframe"
        :src="fileUrl"
        frameborder="0"
        style="width: 100%; height: 500px"
      ></iframe>
      <span slot="footer">
        <el-button size="small" @click="showFileDialog = false">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import * as  API from "@/api/civilizedNorm/evaluate";
  import {detail} from "@/api/civilizedNorm/checklist";
  import website from "@/config/website";
  import {dateFormat} from "@/util/date";
  import DeptDialog from "@/views/components/UserDeptDialog/DeptDialog";
  import UserDetpDialog from "@/views/components/UserDeptDialog/UserDetpDialog"
  import snows from '@/util/snows'
  export default {
    components: {
      DeptDialog,UserDetpDialog
    },
    data() {
      return {
        hide:'',
        pickerOptionsStart: {
          // 时间不能大于当前时间
          disabledDate: (time) => {
            return time.getTime() < Date.now() - 8.64e7
          }
        },
        fileList:[],
        treeData:[],
        HD_SOURCE_TYPE:[],
        hd_level:[],
        hd_type:[],
        formType: '',
        fileUrl: '',
        headTitle: '',
        dataDeptType: '',
        dataType: '',
        mapBox: false,
        respDeptNameShow: false,
        showFileDialog: false,
        respUserShow: false,
        mapdata: '',
        dataForm1:{
          passFlag:'y',
          respUserName:'',
          respDeptName:'',
          rectifyUserName:'',
          rectifyDeptName:'',
          acceptUserName:'',
          acceptDeptName:'',
          hdType:'',
          rectifyLimitDate:'',
          rectifyUser:'',
          acceptDept:'',
          assessmentTime:dateFormat(new Date()),
          rectifyPlanFlag:'',
          rectifyRequest:'',
          respUser:'',
          hdLevel:'',
          respDeptId:'',
        },
        dataForm: {
          id: '',
          hdCode:'',
          cvDesc:'',
        },
        tableData: [],
        tableLoading: false,
        page: {
          pageSize: 20,
          currentPage: 1,
          total: 0,
        },
        rules: {
          acceptUser: [{
            required: true,
            message: '请选择验收人',
            trigger: 'blur'
          }],
          respDeptName: [{
            required: true,
            message: '请选择责任部门',
            trigger: 'change'
          }],
          rectifyUserName: [{
            required: true,
            message: '请选择整改人',
            trigger: 'change'
          }],
          acceptUserName: [{
            required: true,
            message: '请选择验收人',
            trigger: 'change'
          }],
          rectifyDeptName: [{
            required: true,
            message: '请选择整改部门',
            trigger: 'change'
          }],
          acceptDeptName: [{
            required: true,
            message: '请选择验收部门',
            trigger: 'change'
          }],
          respUserName: [{
            required: true,
            message: '请选择责任人',
            trigger: 'change'
          }],
          remark: [{
            required: true,
            message: '请输入备注',
            trigger: 'blur'
          }],
          rectifyRequest: [{
            required: true,
            message: '请输入整改要求',
            trigger: 'blur'
          }],
          hdLevel: [{
            required: true,
            message: '请选择事项等级',
            trigger: 'blur'
          }],
          acceptDept: [{
            required: true,
            message: '请选择验收部门',
            trigger: 'blur'
          }],
          rectifyLimitDate: [{
            required: true,
            message: '请选择整改期限',
            trigger: 'blur'
          }],
          rectifyDeptId: [{
            required: true,
            message: '请选择整改部门',
            trigger: 'blur'
          }],
          rectifyUser: [{
            required: true,
            message: '请选择整改人',
            trigger: 'blur'
          }],
          cvDesc: [{
            required: true,
            message: '请输入项目描述',
            trigger: 'blur'
          }],
        },
        selectionList: [],
      }
    },
    computed: {

      btnOptions(){
        return [{
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "cancel",
        }]
      },
      headBtnOptions() {
        let result = [];
        if (['add', 'edit'].includes(this.formType)) {
          result.push({
            emit: "head-saveBack",
            type: "button",
            btnOptType: "refer",
          });
          result.push({
            emit: "head-save",
            type: "button",
            btnOptType: "save",
          });
        }
        return result;
      },
    },
    mounted() {
      let type = this.$route.query.type;
      this.hide = this.$route.query.hide;
      this.formType = type;
      if (type == 'edit') {
        this.headTitle = '事项信息';
        this.dataForm.id = this.$route.query.id;
        this.getPrjbasicinfoDetail();
      } else if (type == 'view') {
        this.headTitle = '事项信息';
        this.dataForm.id = this.$route.query.id;
        this.getPrjbasicinfoDetail();
      }
      this.getCode('HD_SOURCE_TYPE')
      this.getCode('hd_level')
      this.getCode('hd_type')

    },
    methods: {
      handlePreview(file){
        snows.downloadFile1(file.link,file.name)
      },
      respDeptNameFun(row){
        if(this.dataDeptType == 'respDeptName'){
          this.$set(this.dataForm1,'respDeptName',row.deptName)
          this.$set(this.dataForm1,'rectifyDeptName',row.deptName)
          this.$set(this.dataForm1,'rectifyDeptId',row.id)
          this.$set(this.dataForm1,'respDeptId',row.id)
        }else if(this.dataDeptType == 'rectifyDeptName'){
          this.$set(this.dataForm1,'rectifyDeptName',row.deptName)
          this.$set(this.dataForm1,'rectifyDeptId',row.id)
        }else {
          this.$set(this.dataForm1,'acceptDept',row.id)
          this.$set(this.dataForm1,'acceptDeptName',row.deptName )
        }
        this.$forceUpdate()
        this.respDeptNameShow=false

        console.log("进行输出人员-------------------",row)
      },
      handleUserShow(type){
        this.dataType=type
        this.respUserShow=true;
      },
      handleDeptShow(type){
        this.dataDeptType=type
        this.respDeptNameShow=true;
      },
      respUserFun(row,deptId,treeDeptName){
        if(this.dataType == 'respUserName'){
          this.$set(this.dataForm1,'respUser',row.id)
          this.$set(this.dataForm1,'respUserName',row.realName)
          this.$set(this.dataForm1,'rectifyUser',row.id)
          this.$set(this.dataForm1,'rectifyUserName',row.realName)
          this.$set(this.dataForm1,'respDeptId',deptId)
          this.$set(this.dataForm1,'respDeptName',treeDeptName)
          this.$set(this.dataForm1,'rectifyDeptId',deptId)
          this.$set(this.dataForm1,'rectifyDeptName',treeDeptName)
        }else if(this.dataType == 'rectifyUserName'){
          this.$set(this.dataForm1,'rectifyUser',row.id)
          this.$set(this.dataForm1,'rectifyUserName',row.realName)
          this.$set(this.dataForm1,'rectifyDeptId',deptId)
          this.$set(this.dataForm1,'rectifyDeptName',treeDeptName)
        }else {
          this.$set(this.dataForm1,'acceptUser',row.id)
          this.$set(this.dataForm1,'acceptUserName',row.realName)
          this.$set(this.dataForm1,'acceptDept',deptId)
          this.$set(this.dataForm1,'acceptDeptName',treeDeptName)
        }
        this.$forceUpdate()
        this.respUserShow=false
        console.log("进行输出人员-------------------",row)
      },

      getCode(code){
        API.HD_SOURCE_TYPE(code).then(res=>{
          this[code]=res.data.data
        })
      },

      headCancel() {
        this.$router.$avueRouter.closeTag();
        this.$router.back();
      },
      // 保存
      headSave(boolean) {
        this.$refs.dataForm1.validate((valid) => {
          if (valid) {
            this.$loading();
            let url=this.dataForm1.id ? API.update: API.save
            API.save({
              ...this.dataForm1,
              hdStatus:boolean ?'unrectify': 'prepare',
              cnId:this.dataForm.id,
            }).then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  message:boolean ? '提交成功' :"保存成功",
                  type: "success",
                });
                if (boolean) {
                  this.$router.$avueRouter.closeTag();
                  this.$router.back();
                }else {
                  this.getPrjbasicinfoDetail()
                }

                this.$loading().close();
              }
            }).catch(err=>{
              this.$loading().close();
            })
          }
        });

      },
      // 根据ID查表单详情
      getPrjbasicinfoDetail() {
        detail({
          id: this.dataForm.id
        }).then((res) => {
          if (res.data.code == 200) {
            this.dataForm = res.data.data;
            if(this.formType == 'view' && JSON.stringify(res.data.data.cnAssessmentVO) != '{}'){
              this.dataForm1 = res.data.data.cnAssessmentVO;
            }
            if(this.dataForm.hdStatus == 'unassess' && JSON.stringify(res.data.data.cnAssessmentVO) != '{}'){
              this.dataForm1 = res.data.data.cnAssessmentVO;
            }
            this.dataForm1.passFlag=!this.dataForm1.passFlag ?'y':this.dataForm1.passFlag;
            this.dataForm1.assessmentTime=!this.dataForm1.assessmentTime ? dateFormat(new Date()) :this.dataForm1.assessmentTime
            if(JSON.stringify(res.data.data.cnAssessmentVO) != '{}'){
              this.dataForm1.id=res.data.data.cnAssessmentVO.id
            }
            this.treeData=JSON.parse(this.dataForm.hdUrl)

          }
        })
      },

      getNodeClick(node) {
        this.selectNode = node;
        this.showFile(node);
      },
      showFile(item) {
        this.fileUrl =
          process.env.VUE_APP_BASE_KKFILEURL+"?url=" +
          encodeURIComponent(Base64.encode(item.link));
        this.showFileDialog = true;
        //exls表格预览隐藏打印按钮
        if (process.env.NODE_ENV === "production") {
          const iframe = this.$refs.fileIframe;
          const iframeDocument =
            iframe.contentDocument || iframe.contentWindow.document;

          // 在 iframe 内部隐藏元素
          if (iframeDocument) {
            const elementToHide = iframeDocument.getElementById("button-area");
            if (elementToHide) {
              elementToHide.style.display = "none";
            }
          }
        }
      },
    }
  }

</script>
<style lang="scss" scoped>
  .formContentBox {
    padding: 12px;
    height: calc(100% - 76px);
  }

  .formMain {
    width: 100%;
    overflow: auto;
    height: 100%;
  }
  .step-box{
    background-color: #ffffff;
    width: 125px;
  }
  .form-box{
    background-color: #ffffff;
    width: calc(100% - 135px);
    height: 100%;
    overflow: scroll;
  }
  .formTopic {
    width: 100%;
    height: 40px;
    line-height: 40px;
    border-bottom: 1px solid rgb(187, 187, 187);
    font-weight: 600 !important;
    font-size: 18px;
  }

  .shuttleBackBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;

    .el-tabs {
      width: 100%;
    }

    .table-box {
      width: calc(100% - 280px);
    }
  }

  .shutleBack {
    width: 33%;
    background: #ffffff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
  }

  .leftRightBtn {
    margin: 211px 12px;
  }
  .checklist-edit{
    display: flex;
    height: 100%;
    justify-content: space-between;
  }
  .shutleTitle {
    width: 100%;
    background-color: #f5f7fa;
    height: 40px;
    line-height: 40px;
    text-indent: 12px;
    border-bottom: 1px solid #ebeef5;
    font-size: 16px;
  }

  .shutContent {
    padding: 6px;
    height: 400px;
    overflow: auto;
  }

  .deptBox {
    line-height: 40px;
    color: #333333;
    border-bottom: 1px solid #ebeef5;
    font-size: 13px;
    display: flex;
  }

  .unitBox {
    width: 100%;
    display: flex;
    background-color: #f5f7fa;
    border-bottom: 1px solid #ebeef5;
  }

  .unitTitle {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    font-size: 16px;
    cursor: pointer;
    border-right: 1px solid #ebeef5;
  }
   .content {
    display: flex;
    align-items: center;
    .img-content {
      width: 188px;
      height: 100px;
      padding: 3px;
      position: relative;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin-right: 12px;
      .delete-icon {
        position: absolute;
        top: -6px;
        right: -6px;
        font-size: 16px;
        color: red;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

  }
</style>
